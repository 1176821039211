<template>
    <div class="page">
        <div class="page-header">
            <h1 class="page-header__text">Forms</h1>
        </div>

        <HeadedBlock :heading="'Test Form Controls'">
            <div class="block__content">
                <FormWrapper>
                    <FormItem v-for="key in form.fieldKeys" :key="key" v-bind="form.items[key].props" :name="key">
                        <component
                            :is="form.items[key].component"
                            :name="key"
                            v-model="form.items[key].value"
                            v-bind="form.items[key].props"
                            @update-field="onUpdateFieldValue"
                        />
                    </FormItem>
                    <FormError v-if="form.errors.length" :errors="form.errors" />
                    <FormButtons>
                        <Button type="secondary" @click="$router.go(-1)">Back</Button>
                        <Button @click="onSubmit">Submit</Button>
                    </FormButtons>
                </FormWrapper>
            </div>
        </HeadedBlock>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import Form from '@/components/form/Form';
// import validateField from '@/helpers/validator';
import HeadedBlock from '@/components/common/HeadedBlock';
import FormWrapper from '@/components/form/wrapper';
import FormButtons from '@/components/form/buttons.vue';
import FormItem from '@/components/form/item';
import Button from '@/components/common/Button.vue';

export default {
    name: 'TestForms',
    components: {
        HeadedBlock,
        FormWrapper,
        FormButtons,
        FormItem,
        Button,
    },
    data() {
        return {
            form: new Form({
                action: this.formAction,
                validateHandler: this.validateHandler,
                successHandler: this.successHandler,
                failedHandler: this.failedHandler,
                items: {
                    inputLink: {
                        type: 'field',
                        component: 'inputLink',
                        props: {
                            rules: [],
                            label: 'inputLink',
                            placeholder: 'Enter something',
                        },
                    },
                    inputText: {
                        type: 'field',
                        component: 'InputText',
                        props: {
                            rules: ['noEmpty'],
                            label: 'inputText',
                            placeholder: 'Enter something',
                        },
                    },
                    inputTextPassword: {
                        type: 'field',
                        component: 'InputText',
                        props: {
                            rules: ['noEmpty'],
                            type: 'password',
                            label: 'inputText with type Password',
                            placeholder: 'Enter password',
                        },
                    },
                    InputArrayEmails: {
                        type: 'field',
                        component: 'InputArray',
                        props: {
                            rules: ['email', 'noEmpty'],
                            label: 'Emails InputArray without values',
                            placeholder: 'Enter new',
                        },
                    },
                    InputArrayPhone: {
                        value: ['+791988877766', '+791988877767'],
                        type: 'field',
                        component: 'InputArray',
                        props: {
                            rules: ['phone', 'noEmpty'],
                            label: 'Phones InputArray with values',
                            placeholder: 'Enter new',
                        },
                    },
                    Textarea: {
                        value: '',
                        type: 'field',
                        component: 'Textarea',
                        props: {
                            rules: ['noEmpty'],
                            label: 'Textarea',
                            placeholder: 'Enter text',
                        },
                    },
                    InputLink: {
                        value: '',
                        type: 'field',
                        component: 'InputLink',
                        props: {
                            rules: ['noEmpty'],
                            label: 'InputLink',
                            placeholder: 'Enter text',
                        },
                    },
                    Select: {
                        value: '',
                        type: 'field',
                        component: 'Select',
                        props: {
                            rules: [],
                            label: 'Select with simple items',
                            placeholder: 'Choose item',
                            items: [2, 3, 4, 5, 6],
                        },
                    },
                    SelectWithItems: {
                        value: '',
                        type: 'field',
                        component: 'Select',
                        props: {
                            rules: [],
                            label: 'Select with object items',
                            placeholder: 'Choose item',
                            items: [
                                {
                                    text: 'entities.option.view.button',
                                    value: 'buttons',
                                },
                                {
                                    text: 'entities.option.view.select',
                                    value: 'select',
                                },
                                {
                                    text: 'test no translate',
                                    value: 'no translate',
                                },
                            ],
                        },
                    },
                    SelectWithValue: {
                        value: 'buttons',
                        type: 'field',
                        component: 'Select',
                        props: {
                            rules: [],
                            label: 'Select with object items',
                            placeholder: 'Choose item',
                            items: [
                                {
                                    text: 'entities.option.view.button',
                                    value: 'buttons',
                                },
                                {
                                    text: 'entities.option.view.select',
                                    value: 'select',
                                },
                                {
                                    text: 'test no translate',
                                    value: 'no translate',
                                },
                            ],
                        },
                    },
                    SelectCatalog: {
                        value: '',
                        type: 'field',
                        component: 'SelectCatalog',
                        props: {
                            rules: ['noEmpty'],
                            label: 'SelectCatalog',
                            placeholder: 'Choose catalog',
                        },
                    },
                    SelectCatalogWithValue: {
                        value: 'c90adadd-6cf6-4519-9e52-c6566c478834',
                        type: 'field',
                        component: 'SelectCatalog',
                        props: {
                            rules: ['noEmpty'],
                            label: 'SelectCatalogWithValue',
                            placeholder: 'Choose catalog',
                        },
                    },
                },
            }),
        };
    },
    computed: {},
    methods: {
        formAction(formData) {
            return new Promise((resolve) => {
                resolve({
                    success: true,
                    data: formData,
                });
            });
        },
        validateHandler(isValid) {
            console.log('validateHandler', isValid);
        },
        successHandler(responseData) {
            console.log('successHandler', responseData);
        },
        failedHandler(response) {
            console.log('failedHandler', response);
        },
        onSubmit() {
            this.form.submit();
        },
        onUpdateFieldValue: debounce(function(payload) {
            const { name, group } = payload;
            this.form.validateField(name, group);
        }, 600),
    },
};
</script>

<style lang="scss"></style>
